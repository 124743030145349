/* eslint-disable react/display-name */
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';
import { MARK_LINK, render } from 'storyblok-rich-text-react-renderer';

import styles from './Text.module.scss';

const adaptivePresets = (size, mobile = false) => {
  const sizes = {
    h1: styles[`${mobile ? 'mobile-' : ''}u-h1`],
    h2: styles[`${mobile ? 'mobile-' : ''}u-h2`],
    h3: styles[`${mobile ? 'mobile-' : ''}u-h3`],
    h4: styles[`${mobile ? 'mobile-' : ''}u-h4`],
    h5: styles[`${mobile ? 'mobile-' : ''}u-h5`],
    h6: styles[`${mobile ? 'mobile-' : ''}u-h6`],
    example: styles['other-styles-later']
  };

  return sizes[size];
};

interface IProps {
  content: any;
  align?: string;
  maxWidth?: string;
  isSectionCentered?: boolean;
  largeParagraphText?: boolean;
  textXS?: boolean;
  justify?: string;
  fontFamily?: string;
  fontSize?: string;
  mobileFontSize?: boolean;
  color?: string;
  colorPreset?: string;
  textSize?: string;
  mobileTextSize?: string;
  lineHeight?: string;
  displayListInline?: boolean;
}

const RichText: React.FC<IProps> = ({
  content,
  align = 'left',
  maxWidth,
  isSectionCentered,
  largeParagraphText,
  textXS = false,
  justify,
  fontFamily,
  fontSize = '',
  mobileFontSize,
  color,
  colorPreset,
  textSize,
  lineHeight,
  displayListInline,
  mobileTextSize
}) => {
  return (
    <div
      style={
        {
          maxWidth: maxWidth ? `${maxWidth}px` : 'unset',
          ...(fontSize && { fontSize }),
          ...(fontFamily && { '--font': fontFamily || 'inherit' }),
          color: color || colorPreset || 'var(--color-primary)',
          ...(lineHeight && { '--line-height': lineHeight })
        } as React.CSSProperties
      }
      className={clsx(
        {
          [styles.text]: true,
          [styles[`align-${align}`]]: align,
          [styles.sectionCentered]: isSectionCentered,
          'large-text': largeParagraphText,
          [styles['text-xs']]: textXS,
          [styles[`justify-${justify}`]]: justify,
          [styles.hasColorParameter]: color || colorPreset,
          [styles.hasFontSizeParameter]: fontSize,
          [styles.displayListInline]: displayListInline,
          [styles.mobileFontSize]: mobileFontSize
        },
        textSize && adaptivePresets(textSize),
        mobileTextSize && adaptivePresets(mobileTextSize, true),
        styles.boldStyles,
        styles.italicStyles,
        styles.codeInlineStyles
      )}
    >
      {render(content, {
        markResolvers: {
          [MARK_LINK]: (children, props) => {
            const { href, target, linktype } = props;
            if (linktype === 'email') {
              // Email links: add `mailto:` scheme and map to <a>
              return <a href={`mailto:${href}`}>{children}</a>;
            }
            if (href && href.match(/^(https?:)?\/\//)) {
              // External links: map to <a>
              return (
                <a href={href} target={target}>
                  {children}
                </a>
              );
            }
            // Internal links: map to <Link>
            return (
              <Link href={href}>
                <a target={target}>{children}</a>
              </Link>
            );
          }
        }
      })}
    </div>
  );
};

export default RichText;
